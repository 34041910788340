import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { client } from "./graphql/apollo-client";
import { ApolloProvider } from "@apollo/client";
import UserProvider from "./context/user";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "./components/ui/toast";
import { Toaster } from "./components/ui/toaster";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ToastProvider>
      <ApolloProvider client={client}>
        <UserProvider>
          <BrowserRouter>
            <App />
            <Toaster />
          </BrowserRouter>
        </UserProvider>
      </ApolloProvider>
    </ToastProvider>
  </React.StrictMode>
);
