import { jwtDecode } from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "../components/ui/use-toast";
import { LOGIN_USER } from "../graphql/Mutations";
import { client } from "../graphql/apollo-client";

interface AuthProvider {
  children?: React.ReactNode;
}

type Permission = "FINANCE.WRITE" | "USER_CREATE";
interface User {
  _id: string;
  permissions: Permission[];
}

export const UserContext = createContext<{
  user: User | null;
  loading: boolean | undefined;
  logInUser:
    | ((data: { email?: string; password?: string } | null) => void)
    | undefined;
  logOutUser: (() => void) | undefined;
  loginLoading: boolean;
}>({
  user: null,
  loading: undefined,
  loginLoading: false,
  logInUser: () => {},
  logOutUser: () => {},
});

export const UserProvider = ({ children }: AuthProvider) => {
  const [loading, setLoading] = useState(true);
  const [loginLoading, setLoginLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [tokenExpireTime, setTokenExpireTime] = useState<number | undefined>(
    undefined
  );

  async function logInUser(data: { email?: string; password?: string } | null) {
    setLoginLoading(true);
    try {
      const response: any = await client.mutate({
        mutation: LOGIN_USER,
        variables: {
          email: data?.email,
          password: data?.password,
        },
      });

      const token = response?.data?.login?.token;

      if (token) {
        const loginData: any = token && jwtDecode(token);
        localStorage?.setItem("token", token);

        setUser({
          _id: loginData?.unique_name,
          permissions: loginData?.Roles ? JSON.parse(loginData?.Roles) : [],
        });
        setTokenExpireTime(loginData?.exp ?? undefined);
      }

      setLoginLoading(false);
    } catch (error) {
      toast({
        title: "Dicka shkoj keq!",
        description:
          "Ju lutemi të siguroheni që të dhënat e vendosura të jenë të sakta.",
      });
      setLoginLoading(false);
    }
  }

  function logOutUser() {
    localStorage.removeItem("token");
    setUser(null);
    setLoading(false);
    setLoginLoading(false);
    setTokenExpireTime(undefined);
  }

  useEffect(() => {
    const isTokenExpired = () => {
      if (!tokenExpireTime) return true;
      const now = new Date();
      const expiration = new Date(tokenExpireTime * 1000);
      return now > expiration;
    };

    const checkExpiration = () => {
      if (isTokenExpired()) {
        setUser(null);
        setTokenExpireTime(undefined);
        setLoading(false);
        setLoginLoading(false);
        localStorage.removeItem("token");

        tokenExpireTime &&
          toast({
            title: "Sesioni përfundoi",
            description:
              "Ju lutemi të kyçeni përsëri për të vazhduar përdorimin.",
          });
      }
    };

    const intervalId = user && setInterval(checkExpiration, 1000);

    return () => {
      if (intervalId) {
        return clearInterval(intervalId);
      }
      return;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpireTime]);

  useEffect(() => {
    const token = localStorage?.getItem("token");
    const data: any = token && jwtDecode(token);
    if (data) {
      setUser({
        _id: data?.unique_name,
        permissions: data?.Roles ? JSON?.parse(data?.Roles) : [],
      });
      setLoading(false);
      setLoginLoading(false);
      setTokenExpireTime(data?.exp ?? undefined);
    } else {
      setUser(null);
      setLoading(false);
      setLoginLoading(false);
      setTokenExpireTime(undefined);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ user, loginLoading, loading, logInUser, logOutUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
