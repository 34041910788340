import { gql } from "@apollo/client";

export const LOGIN_USER = gql`
  mutation ($email: String!, $password: String!) {
    login(request: { email: $email, password: $password }) {
      token
    }
  }
`;

export const CHANGE_PAYMENT_STATUS = gql`
  mutation ($companyId: UUID!, $status: PaymentStatus!) {
    changePaymentStatus(request: { companyId: $companyId, status: $status })
  }
`;

export const CHANGE_COMPANY_STATUS = gql`
  mutation ($companyId: UUID!, $status: CompanyStatus!) {
    changeCompanyStatus(request: { companyId: $companyId, status: $status })
  }
`;

export const REGISTER_USER = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $birthday: DateTime!
    $phone: String!
    $privileges: [UUID!]!
  ) {
    register(
      request: {
        birthday: $birthday
        email: $email
        firstName: $firstName
        lastName: $lastName
        password: $password
        phone: $phone
        privileges: $privileges
      }
    )
  }
`;

export const ADD_PRIVILEGES_TO_USER = gql`
  mutation ($userToAddId: UUID!, $privileges: [PrivilegeToUserInput!]!) {
    addPrivilegeToUser(
      request: { privileges: $privileges, userToAddId: $userToAddId }
    )
  }
`;

export const CREATE_SUBJECT = gql`
  mutation (
    $companyName: String!
    $nipt: String!
    $phone: String!
    $buildingSurface: Decimal!
    $businessType: String!
    $categoryId: UUID!
    $comercialSurface: Decimal!
    $companyEnvironmentCode: Int!
    $coveredParkingSurface: Decimal!
    $industrialSurface: Decimal!
    $landSurface: Decimal!
    $publicSurface: Decimal!
    $taxCodification: String!
    $tables: [TablesDTOInput!]!
    $vehicles: [VehicleTaxInput!]!
    $companyStatus: CompanyStatus!
    $paymentStatus: PaymentStatus!
  ) {
    createSubject(
      request: {
        companyName: $companyName
        nipt: $nipt
        phone: $phone
        buildingSurface: $buildingSurface
        businessType: $businessType
        categoryId: $categoryId
        comercialSurface: $comercialSurface
        companyEnvironmentCode: $companyEnvironmentCode
        coveredParkingSurface: $coveredParkingSurface
        industrialSurface: $industrialSurface
        landSurface: $landSurface
        publicSurface: $publicSurface
        taxCodification: $taxCodification
        vehicles: $vehicles
        companyStatus: $companyStatus
        paymentStatus: $paymentStatus
        userId: null
        tables: $tables
      }
    )
  }
`;

export const GENERATE_BILL = gql`
  mutation ($companyId: UUID!) {
    generateCompanyBill(request: { companyId: $companyId, userId: null })
  }
`;

export const CREATE_TAX = gql`
  mutation ($category: Category!, $name: String!, $value: Decimal!) {
    createTax(request: { category: $category, name: $name, value: $value })
  }
`;

export const ADD_TAXES_TO_SUBJECT = gql`
  mutation ($companyId: UUID!, $taxesId: [UUID!]!) {
    addTaxToSubject(request: { companyId: $companyId, taxesId: $taxesId })
  }
`;

export const REMOVE_TAX_TO_OBJECT = gql`
  mutation ($companyId: UUID!, $taxId: UUID!) {
    removeTaxFromSubject(request: { companyId: $companyId, taxId: $taxId })
  }
`;

export const CHANGE_BILL_PAYMENT_STATUS = gql`
mutation($billId: UUID!){
  changeBillStatus(request: {
    billId:$billId
    userId:null
  })
}
`;
