import { useQuery } from "@apollo/client";
import { TaxesEntitiesTable } from "./taxesData-table"
import { GET_TAXES_QUERY } from "../../graphql/Queries";

const TaxesPageComponent = () => {
  const {
    data: taxesData,
    loading: taxesDataLoading,
    error: taxesDataError,
    } = useQuery(GET_TAXES_QUERY);
  return (
    <div>
      <TaxesEntitiesTable taxesData={taxesData} taxesDataLoading={taxesDataLoading} taxesDataError={taxesDataError} />
    </div>
  )
}

export default TaxesPageComponent