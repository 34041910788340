import { useQuery } from "@apollo/client";
import { GET_USERS_QUERY, PRIVILEGES_QUERY } from "../../graphql/Queries";
import { UsersEntitiesTable } from "./usersData-table";

const SystemUsersPageComponent = () => {
  const {
    data: usersData,
    loading: usersDataLoading,
    error: usersDataError,
  } = useQuery(GET_USERS_QUERY);

  const { data: privilegesData } = useQuery(PRIVILEGES_QUERY);
  return (
    <div>
      <UsersEntitiesTable
        usersData={usersData}
        usersDataLoading={usersDataLoading}
        usersDataError={usersDataError}
        privilegesData={privilegesData}
      />
    </div>
  );
};

export default SystemUsersPageComponent;
