import { useMutation } from "@apollo/client";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown } from "lucide-react";
import moment from "moment";
import * as React from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { toast } from "../../components/ui/use-toast";
import { REGISTER_USER } from "../../graphql/Mutations";
import { GET_USERS_QUERY } from "../../graphql/Queries";

export type Entity = {
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
  phone: string;
  id: string;
  privileges: string[];
};

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "firstName",
    header: "Emri",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("firstName")}
      </div>
    ),
  },
  {
    accessorKey: "lastName",
    header: "Mbiemri",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("lastName")}
      </div>
    ),
  },
  {
    accessorKey: "birthday",
    header: "Datëlindja",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("birthday")
          ? moment(row.getValue("birthday")).format("DD.MM.YYYY")
          : "-"}
      </div>
    ),
  },
  {
    accessorKey: "email",
    header: "E-mail",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("email") ? row.getValue("email") : "-"}
      </div>
    ),
  },
  // {
  //   accessorKey: "phone",
  //   header: "Nr. Tel",
  //   cell: ({ row }) => (
  //     <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
  //       {row.getValue("phone") ? row.getValue("phone") : "-"}
  //     </div>
  //   ),
  // },
  // {
  //   accessorKey: "privileges",
  //   header: "Privilegjet",
  //   cell: ({ row }) => console.log("privileges", row.getValue("privileges")),
  // },
  // {
  //   id: "actions",
  //   enableHiding: false,
  //   cell: ({ row }) => {
  //     return <UserPrivilegesChange row={row} />;
  //   },
  // },
];

// const UserPrivilegesChange: React.ComponentType<{ row: Row<Entity> }> = ({
//   row,
// }: {
//   row: any;
// }) => {
//   const [data, setData] = React.useState<any>();
//   const [privilegeAccess, setPrivilegeAccess] = React.useState("READ");
//   const [selectedPrivilegeIds, setSelectedPrivilegeIds] = React.useState<
//     string[]
//   >(row.getValue("privileges").map((prev: any) => prev.privilege.id));

//   const handleGetPrivileges = () => {
//     client
//       .query({
//         query: PRIVILEGES_QUERY,
//         variables: {},
//       })
//       .then((res) => {
//         setData(res.data);
//       });
//   };

//   const handleChangePrivileges = (privilegeId: any) => {
//     setSelectedPrivilegeIds((prevPrivilegeIds) => {
//       const newPrivilegeIds = prevPrivilegeIds.includes(privilegeId)
//         ? prevPrivilegeIds.filter((id) => id !== privilegeId)
//         : [...prevPrivilegeIds, privilegeId];
//       return newPrivilegeIds;
//     });
//   };

//   // Mutations
//   const [addPrivilegesToUser] = useMutation(ADD_PRIVILEGES_TO_USER, {
//     variables: {
//       userToAddId: row.original.id,
//       privileges: selectedPrivilegeIds,
//     },
//     onCompleted: () => {
//       toast({
//         title: "Privilegjet u ndryshuan me sukses",
//         description: "Privilegjet e përdoruesit sapo u ndryshuan me sukses",
//       });
//     },
//     onError: (err) => {
//       toast({
//         variant: "destructive",
//         title: "Problem në ndryshimin e privilegjit",
//         description: `Ndodhi një problem gjatë ndryshimit të privilegjit, ${err?.message}`,
//       });
//     },
//   });

//   // const handleAddPrivilege = () => {
//   //   addPrivilegesToUser({
//   //     variables: {
//   //       userToAddId: row.original.id,
//   //       privileges: selectedPrivilegeIds,
//   //     },
//   //     onCompleted: () => {
//   //       toast({
//   //         title: "Privilegjet u ndryshuan me sukses",
//   //         description: "Privilegjet e përdoruesit sapo u ndryshuan me sukses",
//   //       });
//   //     },
//   //     onError: (err) => {
//   //       toast({
//   //         variant: "destructive",
//   //         title: "Problem në ndryshimin e privilegjit",
//   //         description: `Ndodhi një problem gjatë ndryshimit të privilegjit, ${err?.message}`,
//   //       });
//   //     },
//   //   });
//   // };

//   return (
//     <DropdownMenu>
//       <DropdownMenuTrigger asChild>
//         <div className="flex w-full px-1">
//           <Button variant="ghost" className="h-8 w-full p-0 hover:bg-gray-200">
//             <span className="sr-only">Hap menunë</span>
//             <MoreHorizontal className="h-4 w-4" />
//           </Button>
//         </div>
//       </DropdownMenuTrigger>
//       <DropdownMenuContent align="end">
//         <DropdownMenuSub>
//           <DropdownMenuSubTrigger onMouseEnter={handleGetPrivileges}>
//             Ndrysho privilegjet e përdoruesit
//           </DropdownMenuSubTrigger>
//           <DropdownMenuPortal>
//             <DropdownMenuSubContent>
//               {data?.allPrivileges?.map((privilege: any, index: any) => (
//                 <div
//                   key={index}
//                   className="flex justify-center items-center gap-32"
//                 >
//                   <DropdownMenuCheckboxItem
//                     key={index}
//                     className="capitalize"
//                     checked={selectedPrivilegeIds.includes(privilege.id)}
//                     onClick={(e) => {
//                       handleChangePrivileges(privilege.id);
//                       e.preventDefault();
//                     }}
//                   >
//                     {String(privilege?.type)}
//                     <Label className="ml-20 mr-2 text-sm">
//                       Akses për te modifikuar të dhënat:
//                     </Label>
//                     <div
//                       className="flex flex-row items-center gap-2"
//                       onClick={(e) => {
//                         e.preventDefault();
//                         e.stopPropagation();
//                       }}
//                     >
//                       <Switch
//                         id={`write${index}`}
//                         onChange={() =>
//                           setPrivilegeAccess(
//                             privilegeAccess === "READ" ? "READ_WRITE" : "READ"
//                           )
//                         }
//                       />
//                     </div>
//                   </DropdownMenuCheckboxItem>
//                 </div>
//               ))}
//               <DropdownMenuSeparator />
//               <DropdownMenuItem
//                 className="bg-amber-300 hover:!bg-amber-200 text-amber-950 font-semibold flex justify-center items-center cursor-pointer"
//                 onClick={() => addPrivilegesToUser()}
//               >
//                 Ruaj
//               </DropdownMenuItem>
//             </DropdownMenuSubContent>
//           </DropdownMenuPortal>
//         </DropdownMenuSub>
//       </DropdownMenuContent>
//     </DropdownMenu>
//   );
// };

export function UsersEntitiesTable(props: any) {
  // const { usersData, usersDataLoading, usersDataError, privilegesData } = props;
  const { usersData, usersDataLoading, usersDataError } = props;

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const [userFormData, setUserFormData] = React.useState({
    birthday: "",
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phone: "",
    privileges: [],
  });
  // const [selectedPrivilegeIds, setSelectedPrivilegeIds] = React.useState<
  //   string[]
  // >(userFormData.privileges);

  const handleAddData = (e: any, privilegeId: string) => {
    if (e.target.name === "privileges") {
      // setSelectedPrivilegeIds((prevPrivilegeIds) => {
      //   if (prevPrivilegeIds.includes(privilegeId)) {
      //     return prevPrivilegeIds.filter((id) => id !== privilegeId);
      //   } else {
      //     return [...prevPrivilegeIds, privilegeId];
      //   }
      // });
    } else {
      setUserFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  //MUTATIONS

  const [registerUser] = useMutation(REGISTER_USER, {
    variables: {
      birthday: userFormData?.birthday,
      email: userFormData?.email,
      firstName: userFormData?.firstName,
      lastName: userFormData?.lastName,
      password: userFormData?.password,
      // privileges: selectedPrivilegeIds,
      privileges: ["b1485a90-337b-4132-9f74-3819dc6ddcd5"],
      phone: userFormData?.phone,
    },
    onCompleted: () => {
      toast({
        title: "Rregjistrimi i kryer me sukses",
        description: "Përdoruesi u shtua me sukses",
      });
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        title: "Rregjistrimi dështoi",
        description: `Shtimi i përdoruesit dështoi, ${err?.message}`,
      });
    },
    refetchQueries: [GET_USERS_QUERY],
  });

  const usersTable = useReactTable({
    data: usersData?.users,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  if (usersDataLoading) {
    return <div>Loading...</div>;
  }

  if (usersDataError) {
    return <div>Error loading data</div>;
  }
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Kërko..."
          value={
            (usersTable.getColumn("firstName")?.getFilterValue() as string) ??
            ""
          }
          onChange={(event) =>
            usersTable
              .getColumn("firstName")
              ?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <div className="ml-auto">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button className="m-auto mx-3" variant="outline">
                Shto Përdorues
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">
                  Rregjistro përdorues!
                </AlertDialogTitle>
                <AlertDialogDescription>
                  <form action="" className="w-full flex flex-col gap-y-4">
                    <div className="flex flex-col gap-2">
                      <label htmlFor="firstName">Emri:</label>
                      <Input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="lastName">Mbiemri:</label>
                      <Input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="birthday">Datëlindja:</label>
                      <Input
                        type="date"
                        id="birthday"
                        name="birthday"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="email">E-mail:</label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="phone">Nr. Tel:</label>
                      <Input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="password">Fjalëkalimi:</label>
                      <Input
                        type="password"
                        id="password"
                        name="password"
                        onChange={(e) => handleAddData(e, "")}
                        required
                      />
                    </div>
                    {/* <div className="flex flex-col gap-2">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button variant="outline">
                            Privilegjet <ChevronDown className="ml-2 h-6 w-6" />
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="center" className="w-36">
                          {privilegesData &&
                            privilegesData?.allPrivileges?.map(
                              (privilege: any, index: any) => (
                                <DropdownMenuCheckboxItem
                                  onClick={(e) => e.preventDefault()}
                                  key={index}
                                  className="capitalize"
                                  checked={selectedPrivilegeIds.includes(
                                    privilege.id
                                  )}
                                  onCheckedChange={() =>
                                    handleAddData(
                                      { target: { name: "privileges" } },
                                      privilege.id
                                    )
                                  }
                                >
                                  {String(privilege?.type)}
                                </DropdownMenuCheckboxItem>
                              )
                            )}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    {selectedPrivilegeIds?.length ? (
                      <div className="flex flex-col gap-2">
                        <label htmlFor="password">
                          Privilegjet e zgjedhura:
                        </label>
                        {selectedPrivilegeIds &&
                          privilegesData?.allPrivileges
                            ?.filter((prev: any) =>
                              selectedPrivilegeIds.includes(prev?.id)
                            )
                            .map((selectedprev: any, index: any) => {
                              return (
                                <div className="w-full flex flex-row flex-wrap">
                                  <p
                                    key={index}
                                    className="bg-amber-200 text-amber-700 font-semibold px-2 py-1 rounded-sm"
                                  >
                                    {selectedprev.type}
                                  </p>
                                </div>
                              );
                            })}
                      </div>
                    ) : (
                      ""
                    )} */}
                  </form>
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>Anullo</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-amber-200 text-amber-950 hover:bg-amber-100"
                  onClick={() => registerUser()}
                >
                  Shto Përdoruesin
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {usersTable
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {usersTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {usersData?.users?.length ? (
              usersTable.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {usersTable.getFilteredSelectedRowModel().rows?.length} nga{" "}
          {usersTable.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => usersTable.previousPage()}
            disabled={!usersTable.getCanPreviousPage()}
          >
            Mbrapa
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => usersTable.nextPage()}
            disabled={!usersTable.getCanNextPage()}
          >
            Përpara
          </Button>
        </div>
      </div>
    </div>
  );
}
