import React from "react";
import { Link, useLocation } from "react-router-dom";
import { cn } from "../lib/utils";

interface NavbarLinkProps {
  to: string;
  label: string;
  icon?: React.ReactNode;
  className?: string;
  selected?: boolean;
}

const NavbarLink = (props: NavbarLinkProps) => {
  const location = useLocation();
  return (
    <Link
      to={props.to}
      className={cn(
        `font-semibold px-3 flex items-center gap-1 text-amber-950 py-2 rounded-lg transform transition-all hover:bg-amber-100`,
        location.pathname === props.to &&
          "bg-amber-300 text-amber-950 hover:bg-amber-300",
        props.className
      )}
    >
      {props.icon && <div className="">{props.icon}</div>}
      {props.label}
    </Link>
  );
};

export default NavbarLink;
