import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronDown, MoreHorizontal } from "lucide-react";
import * as React from "react";

import { Link } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import { Input } from "../../components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { toast } from "../../components/ui/use-toast";
import {
  CHANGE_COMPANY_STATUS,
  CHANGE_PAYMENT_STATUS,
  CREATE_SUBJECT,
} from "../../graphql/Mutations";
import { GET_SUBJECTS_QUERY } from "../../graphql/Queries";
import { client } from "../../graphql/apollo-client";
import { cn } from "../../lib/utils";
import { useMutation } from "@apollo/client";
import { Label } from "../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";
import { Separator } from "../../components/ui/separator";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Checkbox } from "../../components/ui/checkbox";
import AddEntityForm from "./addEntityForm";
interface Table {
  tableCode: number;
  lightingType: number;
  tableSurface: number;
}

export type Entity = {
  companyName: string;
  nipt: string;
  id: string;
  phone: string;
  companyStatus: "AKTIV" | "PASIV";
  paymentStatus: "PAGUAR" | "PAPAGUAR";
  amountToPay: number;
};

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "companyName",
    header: "Emri Subjektit",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("companyName")}
      </div>
    ),
  },
  {
    accessorKey: "nipt",
    header: "NIPT",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("nipt")}
      </div>
    ),
  },
  {
    accessorKey: "phone",
    header: "Nr. Kontakti",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("phone")}
      </div>
    ),
  },
  {
    accessorKey: "companyStatus",
    header: "Statusi",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 capitalize rounded-lg my-2 text-center",
          row.getValue("companyStatus") === "AKTIV"
            ? "bg-green-200 text-green-950 font-semibold"
            : "bg-red-200 text-red-950 font-semibold"
        )}
      >
        {row.getValue("companyStatus") === "AKTIV" ? "Aktiv" : "Pasiv"}
      </div>
    ),
  },
  {
    accessorKey: "amountToPay",
    header: "Shuma për tu paguar",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("amountToPay")}
      </div>
    ),
  },
  {
    accessorKey: "paymentStatus",
    header: "Statusi i Pagesave",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 rounded-lg my-2 text-center",
          row.getValue("paymentStatus") === "PAGUAR"
            ? "bg-green-200 text-green-700 font-semibold"
            : "bg-red-200 text-red-950 font-semibold"
        )}
      >
        {row.getValue("paymentStatus") === "PAGUAR"
          ? "Paguar"
          : "Për tu paguar"}
      </div>
    ),
  },
  {
    id: "actions",
    enableHiding: false,
    cell: ({ row }) => {
      const entity = row.original;
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex w-full px-1">
              <Button
                variant="ghost"
                className="h-8 w-full p-0 hover:bg-gray-200"
              >
                <span className="sr-only">Hap menunë</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {/* <DropdownMenuLabel>Actions</DropdownMenuLabel> */}
            <DropdownMenuItem>
              <Link to={`/subjekti?id=${entity.id}`}>
                Hap profilin e subjektit
              </Link>
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            {entity?.paymentStatus === "PAPAGUAR" ? (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-gray-100">
                    Ndrysho statusin e pagesës
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Jeni i sigurt?</AlertDialogTitle>
                    <AlertDialogDescription className="flex flex-col gap-1">
                      <p>
                        Jeni i sigurt që dëshironi të ndryshoni statusin e
                        pagesave për subjektin{" "}
                        <span className="font-semibold">
                          "{entity?.companyName} (Nipt: {entity.nipt})"
                        </span>{" "}
                        në të paguara?
                      </p>
                      <p className="text-amber-600 italic">
                        Ky veprim është i pakthyeshëm dhe mund të ndikojë në të
                        dhënat tatimore të subjektit. Ju lutemi sigurohuni
                        përpara se të vazhdoni.
                      </p>
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Anullo</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        client
                          .mutate({
                            mutation: CHANGE_PAYMENT_STATUS,
                            variables: {
                              status: "PAGUAR",
                              companyId: entity?.id,
                            },
                            refetchQueries: [GET_SUBJECTS_QUERY],
                          })
                          .then((data) => {
                            toast({
                              title: "Statusi u përditësua",
                              description:
                                "Statusi i pagesës u përditësua me sukses!",
                            });
                          });
                      }}
                    >
                      Shëno si të paguar
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            ) : (
              ""
            )}
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button className="relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:bg-gray-100">
                  Ndrysho statusin e kompanisë
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Jeni i sigurt?</AlertDialogTitle>
                  <AlertDialogDescription className="flex flex-col gap-1">
                    <p>
                      Jeni i sigurt që dëshironi të ndryshoni statusin e
                      kompanisë{" "}
                      <span className="font-semibold">
                        "{entity?.companyName} (Nipt: {entity.nipt})"
                      </span>
                      ?
                    </p>
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Anullo</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      client
                        .mutate({
                          mutation: CHANGE_COMPANY_STATUS,
                          variables: {
                            status:
                              entity?.companyStatus === "AKTIV"
                                ? "PASIV"
                                : "AKTIV",
                            companyId: entity?.id,
                          },
                          refetchQueries: [GET_SUBJECTS_QUERY],
                        })
                        .then((data) => {
                          toast({
                            title: "Statusi u përditësua",
                            description:
                              "Statusi i pagesës u përditësua me sukses!",
                          });
                        });
                    }}
                  >
                    Ndrysho statusin
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
            <DropdownMenuItem
              onClick={() => navigator.clipboard.writeText(entity.nipt)}
            >
              Kopjo NIPT e subjektit
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

export function EntitiesTable(props: any) {
  const {
    subjectsData,
    subjectsDataLoading,
    subjectsDataError,
    taxesData,
    categoriesData,
  } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState({});
  const [paymentStatus, setPaymentStatus] = React.useState("PAPAGUAR");
  const [companyStatus, setCompanyStatus] = React.useState("AKTIV");
  const [businessType, setBusinessType] = React.useState("BV");
  const [taxCodification, setTaxCodification] = React.useState("SF");
  const [tables, setTables] = React.useState<Table[] | null>(null);
  const [vehicles, setVehicles] = React.useState([
    {
      vehicleCode: 1,
      count: 0,
    },
    {
      vehicleCode: 2,
      count: 0,
    },
    {
      vehicleCode: 3,
      count: 0,
    },
    {
      vehicleCode: 4,
      count: 0,
    },
    {
      vehicleCode: 5,
      count: 0,
    },
    {
      vehicleCode: 6,
      count: 0,
    },
  ]);
  const [companyEnvironmentCode, setCompanyEnvironmentCode] =
    React.useState("1");
  const [checkedStates, setCheckedStates] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [newSubjectData, setNewSubjectData] = React.useState<{
    companyName: string;
    nipt: string;
    phone: string;
    advertisementSurface: GLfloat;
    buildingSurface: GLfloat;
    businessType: string;
    comercialSurface: GLfloat;
    companyEnvironmentCode: number;
    coveredParkingSurface: GLfloat;
    industrialSurface: GLfloat;
    landSurface: GLfloat;
    tableSurface: GLfloat;
    publicSurface: GLfloat;
  } | null>(null);

  const handleCountChange = (vehicleCode: any, newCount: any) => {
    setVehicles(
      vehicles.map((vehicle) => {
        if (vehicle.vehicleCode === vehicleCode) {
          return { ...vehicle, count: Number(newCount) };
        }
        return vehicle;
      })
    );
  };

  const handleAddTables = () => {
    const newTable = {
      tableCode: null,
      lightingType: 4,
      tableSurface: null,
    };

    setTables((prevTables): any =>
      prevTables ? [...prevTables, newTable] : [newTable]
    );
  };

  const handleRemoveTables = (tableId: any) => {
    if (tables) {
      const newTables = tables.filter(
        (_: any, index: any) => index !== tableId
      );
      setTables(newTables);
    }
  };

  const handleChangeTable = (index: number, key: keyof Table, value: any) => {
    setTables((prevTables) => {
      const updatedTables = prevTables ? [...prevTables] : [];
      if (updatedTables[index]) {
        updatedTables[index] = { ...updatedTables[index], [key]: value };
      }
      return updatedTables;
    });
  };

  const handleCheckboxChange = (taxId: string, isChecked: any) => {
    setCheckedStates((prev) => ({ ...prev, [taxId]: isChecked }));
  };

  const getCheckedTaxIds = () => {
    const checkedTaxIds = Object.entries(checkedStates)
      .filter(([theTaxId, isChecked]) => isChecked)
      .map(([theTaxId]) => theTaxId);

    return checkedTaxIds;
  };

  const handleAddData = (e: any) => {
    setNewSubjectData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  // Mutations
  const [registerSubject] = useMutation(CREATE_SUBJECT, {
    variables: {
      companyName: newSubjectData?.companyName,
      nipt: newSubjectData?.nipt,
      phone: newSubjectData?.phone,
      companyStatus: companyStatus,
      categoryId: selectedCategory,
      paymentStatus: paymentStatus,
      buildingSurface: Number(newSubjectData?.buildingSurface),
      businessType: businessType,
      comercialSurface: Number(newSubjectData?.comercialSurface),
      companyEnvironmentCode: Number(companyEnvironmentCode),
      coveredParkingSurface: Number(newSubjectData?.coveredParkingSurface),
      industrialSurface: Number(newSubjectData?.industrialSurface),
      landSurface: Number(newSubjectData?.landSurface),
      publicSurface: Number(newSubjectData?.publicSurface),
      taxCodification: taxCodification,
      vehicles: vehicles,
      tables: tables ? tables : [],
    },
    refetchQueries: [GET_SUBJECTS_QUERY],
    onCompleted: () => {
      toast({
        title: "Subjekti u rregjistrua me sukses",
        description: `Subjekti ${newSubjectData?.companyName} sapo u krijua me sukses`,
      });
    },
    onError: (err) => {
      toast({
        variant: "destructive",
        title: "Problem në krijimin e subjektit.",
        description: `Subjekti nuk u krijua, ${err?.message}`,
      });
    },
  });

  const table = useReactTable({
    data: subjectsData?.subjects,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const getVehicleType = (code: any) => {
    switch (code) {
      case 1:
        return " Taksi 4+1";
      case 2:
        return " Taksi 8+1";
      case 3:
        return " Kamion 3.5 ton";
      case 4:
        return " Kamion mbi 3.5 ton";
      case 5:
        return " Kamion mbi 8 ton";
      case 6:
        return " Autobuza";
    }
  };

  if (subjectsDataLoading) {
    return <div>Loading...</div>;
  }

  if (subjectsDataError) {
    return <div>Error loading data</div>;
  }
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <Input
          placeholder="Kërko..."
          value={
            (table.getColumn("companyName")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("companyName")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <div className="ml-auto">
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button className="m-auto mx-3" variant="outline">
                Shto subjekt
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="min-w-[60svw] min-h-[90svh] flex flex-col">
              <AlertDialogHeader>
                <AlertDialogTitle className="text-center">
                  Rregjistro Subjekt !
                </AlertDialogTitle>
                </AlertDialogHeader>
                  <div className="max-h-[75svh] overflow-y-auto px-1">
                    <form action="" className="w-full flex flex-col gap-y-4">
                      <AddEntityForm
                        handleAddData={handleAddData}
                        categoriesData={categoriesData}
                        checkedStates={checkedStates}
                        handleCheckboxChange={handleCheckboxChange}
                        vehicles={vehicles}
                        getVehicleType={getVehicleType}
                        businessType={businessType}
                        setBusinessType={setBusinessType}
                        companyEnvironmentCode={companyEnvironmentCode}
                        setCompanyEnvironmentCode={setCompanyEnvironmentCode}
                        taxCodification={taxCodification}
                        setTaxCodification={setTaxCodification}
                        paymentStatus={paymentStatus}
                        setPaymentStatus={setPaymentStatus}
                        handleCountChange={handleCountChange}
                        companyStatus={companyStatus}
                        setCompanyStatus={setCompanyStatus}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        handleAddTables={handleAddTables}
                        handleChangeTable={handleChangeTable}
                        handleRemoveTables={handleRemoveTables}
                        tables={tables}
                      />
                    </form>
                  </div>
              <AlertDialogFooter>
                <AlertDialogCancel>Anullo</AlertDialogCancel>
                <AlertDialogAction
                  className="bg-amber-200 text-amber-950 hover:bg-amber-100"
                  onClick={() => registerSubject()}
                >
                  Shto Subjektin
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {subjectsData?.subjects?.length ? (
              table.getRowModel().rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell: any) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows?.length} nga{" "}
          {table.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
          {table.getFilteredSelectedRowModel().rows?.length} nga{" "}
          {table.getFilteredRowModel().rows?.length} rrjeshta zgjedhur.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table?.previousPage()}
            disabled={!table?.getCanPreviousPage()}
          >
            Mbrapa
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table?.nextPage()}
            disabled={!table?.getCanNextPage()}
          >
            Përpara
          </Button>
        </div>
      </div>
    </div>
  );
}
