import {
  Building,
  LayoutDashboard,
  LogOut,
  Receipt,
  Users,
} from "lucide-react";
import NavbarLink from "./NavbarLink";
import { ScrollArea } from "./ui/scroll-area";
import { Link } from "react-router-dom";
import useUser from "../hooks/useUser";

const NavbarContent = () => {
  const { logOutUser } = useUser();
  return (
    <>
      <div className="h-14 flex-none flex justify-center items-center">
        <Link to="/" className="select-none">
          <div className="rounded flex justify-center items-center">
            <h1 className="text-xl text-amber-950 font-bold whitespace-nowrap">
              🪙 COIN{" "}
              <span className="bg-amber-300 px-[5px] rounded-md">MASTER</span>
            </h1>
          </div>
        </Link>
      </div>
      <ScrollArea className="w-full h-[calc(100%-104px)] lg:h-full overflow-x-auto">
        <div className="flex flex-col justify-start items-stretch gap-4">
          <NavbarLink
            to="/"
            icon={<LayoutDashboard className="w-4 h-4" />}
            label="Paneli"
          />
          <NavbarLink
            to="/subjektet"
            icon={<Building className="w-4 h-4" />}
            label="Subjektet"
          />
          <NavbarLink
            to="/taksat"
            icon={<Receipt className="w-4 h-4" />}
            label="Taksat"
          />
          <NavbarLink
            to="/perdoruesit"
            icon={<Users className="w-4 h-4" />}
            label="Përdoruesit"
          />
        </div>
      </ScrollArea>
      <div onClick={logOutUser}>
        <NavbarLink
          to="/"
          className="mb-4 bg-red-500 hover:bg-red-500 text-white flex justify-center"
          icon={<LogOut className="w-4 h-4" />}
          label="Dil"
        />
      </div>
    </>
  );
};

export default NavbarContent;
