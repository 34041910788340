import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/PrivateRoute";
import useUser from "./hooks/useUser";
import AuthPage from "./pages/Auth";
import DashboardPage from "./pages/Dashboard/";
import EntitiesPage from "./pages/Entities/";
import EntityPage from "./pages/Entity/";
import NotFoundPage from "./pages/NotFound";
import SystemUsersPage from "./pages/SystemUsers/";
import TaxesPage from "./pages/Taxes/";

const App = () => {
  const { user, loading } = useUser();

  return (
    <>
      {!loading && (
        <Routes>
          <Route
            path="/"
            index
            element={
              user ? (
                <ProtectedRoute isAllowed={!!user} redirectPath="/">
                  <DashboardPage />
                </ProtectedRoute>
              ) : (
                <AuthPage />
              )
            }
          />
          <Route
            path="subjektet"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <EntitiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="subjekti"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <EntityPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="taksat"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <TaxesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="perdoruesit"
            element={
              <ProtectedRoute isAllowed={!!user} redirectPath="/">
                <SystemUsersPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
};

export default App;
