import { PanelLeftOpen } from "lucide-react";
import React from "react";
import NavbarContent from "./NavbarContent";
import { Sheet, SheetContent, SheetTrigger } from "./ui/sheet";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { children } = props;
  return (
    <>
      <div className="flex min-h-[100lvh] relative">
        <nav className="hidden md:flex flex-col w-[244px] sticky top-0 h-[100lvh] px-4 gap-4">
          <NavbarContent />
        </nav>
        <div className="flex-1 relative0">
          <header className="bg-white h-14 sticky top-0 flex justify-between items-center px-4 z-10">
            <div className="flex justify-between w-full md:justify-end">
              <Sheet>
                <SheetTrigger className="md:hidden flex justify-center items-center">
                  <PanelLeftOpen />
                </SheetTrigger>
                <SheetContent side="left" className="md:hidden">
                  <NavbarContent />
                </SheetContent>
              </Sheet>
              <a
                href="https://www.laconics.io"
                target="_blank"
                rel="noreferrer"
                className="text font-semibold"
              >
                Mundësuar nga{" "}
                <span className="font-bold text-[#62B9B1]">LACONICS</span> ↗
              </a>
            </div>
          </header>
          <main className="p-4 flex-none">{children}</main>
          <footer></footer>
        </div>
      </div>
      {/* <footer></footer> */}
    </>
  );
};

export default Layout;
