import { useMutation } from "@apollo/client";
import { Plus, Trash2 } from "lucide-react";
import React, { useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { Button } from "../../components/ui/button";
import { Checkbox } from "../../components/ui/checkbox";
import { ScrollArea } from "../../components/ui/scroll-area";
import { Separator } from "../../components/ui/separator";
import { toast } from "../../components/ui/use-toast";
import {
  ADD_TAXES_TO_SUBJECT,
  REMOVE_TAX_TO_OBJECT,
} from "../../graphql/Mutations";
import { ENTITY_BY_ID_QUERY } from "../../graphql/Queries";
const EntityTaxes = (props: any) => {
  const { taxesData, entityData, entityTaxesData, id } = props;
  const [checkedStates, setCheckedStates] = useState<{
    [key: string]: boolean;
  }>({});

  // useEffect(() => {
  //   const initialCheckedStates: any = {};
  //   taxesData?.taxes.forEach((tax: any) => {
  //     initialCheckedStates[tax.id] = entityData?.companyById?.companyTaxes.some(
  //       (companyTax: any) => companyTax.tax.id === tax.id
  //     );
  //   });
  //   setCheckedStates(initialCheckedStates);
  // }, [taxesData, entityData]);

  const handleCheckboxChange = (taxId: string, isChecked: any) => {
    setCheckedStates((prev) => ({ ...prev, [taxId]: isChecked }));
  };

  // Mutations
  const [addTaxToSubject] = useMutation(ADD_TAXES_TO_SUBJECT);
  const [removeTaxToSubject] = useMutation(REMOVE_TAX_TO_OBJECT);

  const getCheckedTaxIds = () => {
    const checkedTaxIds = Object.entries(checkedStates)
      .filter(([theTaxId, isChecked]) => isChecked)
      .map(([theTaxId]) => theTaxId);

    return checkedTaxIds;
  };

  const handleAddTaxToSubject = () => {
    const taxIdsToAdd = getCheckedTaxIds();
    addTaxToSubject({
      variables: {
        companyId: id,
        taxesId: taxIdsToAdd,
      },
      refetchQueries: [ENTITY_BY_ID_QUERY],
    })
      .then((response) => {
        toast({
          title: "Taksat u shtuan me sukses",
          description: "Taksat sapo u ndryshuan me sukses",
        });
        setCheckedStates({});
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Problem në shtimin e taksave",
          description: `Pati një problem, ${error?.message}`,
        });
      });
  };

  const handleRemoveTaxToSubject = (taxId: any) => [
    removeTaxToSubject({
      variables: {
        companyId: id,
        taxId: taxId,
      },
      refetchQueries: [ENTITY_BY_ID_QUERY],
    })
      .then(() => {
        toast({
          title: "Taksa u fshi me sukses",
          description: "Taksa sapo u fshi me sukses",
        });
      })
      .catch((error) => {
        toast({
          variant: "destructive",
          title: "Problem për të fshirë taksën",
          description: `Taksa nuk u fshi,${error?.message}`,
        });
      }),
  ];

  return (
    <div className="w-full flex flex-col gap-2">
      <p className="font-medium text-lg">Taksat</p>
      <div className="w-full flex flex-wrap gap-2">
        {entityTaxesData?.generateTaxes ? (
          entityTaxesData?.generateTaxes?.map((tax: any, index: any) => {
            return (
              
                tax?.valueString !== "0" && (
                  <div key={index} className="relative">
                  <p className="flex flex-row items-center justify-center gap-4 px-3 py-1.5 capitalize rounded-md  text-center bg-amber-100 text-sm text-amber-950 font-semibold">
                    {tax?.taxName}: {tax.valueString} leke
                  </p>
                  {/* <div
                    className="absolute top-0 -right-3 w-6 h-6 border border-red-500 bg-red-200 rounded-full flex justify-center items-center"
                    onClick={() => handleRemoveTaxToSubject(tax?.tax?.id)}
                  >
                    <Trash2 className="w-3 h-3 text-red-600 cursor-pointer" />
                  </div> */}
                </div>
                )
              
            );
          })
        ) : (
          <div className="relative">
            <p className="flex flex-row items-center justify-center gap-2 px-4 py-2 capitalize rounded-sm my-2 text-center text-amber-950 font-semibold">
              Asnjë rezultat...
            </p>
          </div>
        )}
        {/* <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button className="px-3.5 py-2 rounded-sm my-2 text-center bg-transparent hover:bg-amber-100 border border-dashed border-amber-300 text-amber-950 font-semibold">
              <Plus className="w-4 h-4 mr-1" /> Shtoi subjektit një taks
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle className="text-center">
                Taksat
              </AlertDialogTitle>
              <AlertDialogDescription>
                <ScrollArea className="h-72 w-full rounded-md border">
                  {taxesData
                    ? taxesData?.taxes.map((tax: any, index: any) => {
                        return (
                          <React.Fragment key={tax.id}>
                            <div
                              className={`w-full flex flex-row items-center justify-between text-sm p-2 ${
                                checkedStates[tax.id] === true
                                  ? "bg-amber-200"
                                  : ""
                              }`}
                            >
                              <p className="flex gap-2">
                                <Checkbox
                                  checked={checkedStates[tax.id]}
                                  onCheckedChange={(isChecked) =>
                                    handleCheckboxChange(tax.id, isChecked)
                                  }
                                />
                                {tax?.name}
                              </p>
                              <p>{tax?.value} Lekë</p>
                            </div>
                            <Separator className="my-2" />
                          </React.Fragment>
                        );
                      })
                    : ""}
                </ScrollArea>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setCheckedStates({})}>Anullo</AlertDialogCancel>
              <AlertDialogAction onClick={handleAddTaxToSubject}>
                Shto taksat
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog> */}
      </div>
    </div>
  );
};

export default EntityTaxes;
