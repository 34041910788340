import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FileSignature, ChevronDown, Eye, ArrowDownToLine } from "lucide-react";
import * as React from "react";
import { Button } from "../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { cn } from "../../lib/utils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { GET_BILLS_QUERY } from "../../graphql/Queries";
import { client } from "../../graphql/apollo-client";
import { toast } from "../../components/ui/use-toast";
import {
  CHANGE_BILL_PAYMENT_STATUS,
  CHANGE_PAYMENT_STATUS,
} from "../../graphql/Mutations";
import { UUID } from "crypto";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Bill from "../../components/Bill";

export type Entity = {
  numberOfBIll: number;
  value: number;
  billSTatus: "PAYED" | "TOPAY";
  debt: number;
  id: UUID;
  companyName: string;
};

export const columns: ColumnDef<Entity>[] = [
  {
    accessorKey: "numberOfBIll",
    header: "Numri faturës",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("numberOfBIll")}
      </div>
    ),
  },
  {
    accessorKey: "value",
    header: "Vlera",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("value")}
      </div>
    ),
  },
  {
    accessorKey: "debt",
    header: "Të prapambetura",
    cell: ({ row }) => {
      const formattedNumber = parseFloat(row.getValue("debt"));
      return (
        <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
          {formattedNumber.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          <span className="font-semibold">Lekë</span>
        </div>
      );
    },
  },
  {
    accessorKey: "period",
    header: "Periudha (DD/MM/YYYY)",
    cell: ({ row }) => (
      <div className="px-3.5 py-2 capitalize rounded-lg my-2 text-center">
        {row.getValue("period")}
      </div>
    ),
  },
  {
    accessorKey: "billSTatus",
    header: "Statusi i Pagesave",
    cell: ({ row }) => (
      <div
        className={cn(
          "px-3.5 py-2 rounded-lg my-2 text-center max-w-md",
          row.getValue("billSTatus") === "PAYED"
            ? "bg-green-200 text-green-700 font-semibold"
            : "bg-red-200 text-red-950 font-semibold"
        )}
      >
        {row.getValue("billSTatus") === "PAYED" ? "Paguar" : "Për tu paguar"}
      </div>
    ),
  },
  {
    accessorKey: "id",
    header: "",
    cell: ({ row }) => {
      const entity = row.original;
      return (
        <div className="flex items-center justify-end gap-4 py-2 capitalize rounded-lg my-2">
          {row.getValue("billSTatus") !== "PAYED" ? (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="outline">Paguaj faturën</Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Jeni i sigurt?</AlertDialogTitle>
                  <AlertDialogDescription className="flex flex-col gap-1">
                    <p>
                      Jeni i sigurt që dëshironi të ndryshoni statusin e
                      pagesave për subjektin{" "}
                      <span className="font-semibold">
                        {/* "{entity?.companyName} (Nipt: {entity.nipt})" */}
                      </span>{" "}
                      në të paguara?
                    </p>
                    <p className="text-amber-600 italic">
                      Ky veprim është i pakthyeshëm dhe mund të ndikojë në të
                      dhënat tatimore të subjektit. Ju lutemi sigurohuni përpara
                      se të vazhdoni.
                    </p>
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Anullo</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      client
                        .mutate({
                          mutation: CHANGE_BILL_PAYMENT_STATUS,
                          variables: {
                            billId: entity.id,
                          },
                          refetchQueries: [GET_BILLS_QUERY],
                        })
                        .then((data) => {
                          toast({
                            title: "Statusi u përditësua",
                            description:
                              "Statusi i pagesës u përditësua me sukses!",
                          });
                        });
                    }}
                  >
                    Shëno si të paguar
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          ) : (
            ""
          )}
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                variant="default"
                className="bg-orange-300 hover:bg-orange-200"
              >
                <Eye className="h-4 w-4 text-white" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className="min-w-[80svw] min-h-[90svh] flex flex-col">
              <AlertDialogHeader className="flex-none">
                <AlertDialogTitle>
                  {entity?.companyName} - Vlerësimi tatimor Bashkia Gramsh
                </AlertDialogTitle>
                <AlertDialogDescription className="flex flex-col gap-1"></AlertDialogDescription>
              </AlertDialogHeader>
              <PDFViewer className="w-full flex-1">
                <Bill bill={entity.id} />
              </PDFViewer>
              <AlertDialogFooter>
                <AlertDialogCancel>Mbyll</AlertDialogCancel>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <PDFDownloadLink
            document={<Bill bill={entity.id} />}
            fileName={`${entity?.companyName}-Vleresimi-Tatimor-Bashkia-Gramsh`}
          >
            <Button variant="outline">
              <ArrowDownToLine className="h-4 w-4 mr-1" />
              <span>Shkarko faturën</span>
            </Button>
          </PDFDownloadLink>
        </div>
      );
    },
  },
];

export function EntityBillTable(props: any) {
  const { entityData, billsData, generateBill } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data: billsData?.companyBills ? billsData.companyBills : undefined,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });
  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <p className="font-medium text-lg">Historiku i faturave</p>
        <div className="ml-auto">
          <Button variant="outline" className="mr-2" onClick={generateBill}>
            Gjenero faturë <FileSignature className="ml-2 h-4 w-4" />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                Filtro të dhënat <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {String(column.columnDef?.header)}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="rounded-md border w-full max-w-[calc(100vw-32px)]">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {billsData?.companyBills ? (
              table.getRowModel().rows.map((row: any) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length}
                  className="h-24 text-center"
                >
                  Asnjë rezultat.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
