import Layout from "../../components/Layout";
import EntitiesPageComponent from "./page";

const EntitiesPage = () => {
  return (
    <Layout>
      <EntitiesPageComponent />
    </Layout>
  );
};

export default EntitiesPage;
