import { gql } from "@apollo/client";

// export const LOGIN_USER = gql``;

export const GET_SUBJECTS_QUERY = gql`
  query {
    subjects{
      id
      companyName
      companyStatus
      paymentStatus
      nipt
      phone
      amountToPay
      companyTaxes {
        id
        tax {
          name
        }
      }
    }
  }
`;

export const GET_TAXES_QUERY = gql`
query {
  taxes(getTaxes: {
    userId:null
  }) {
    id
    name
  }
}
`;

export const GET_USERS_QUERY = gql`
  query {
    users(getUsers: { userId: null }) {
      id
      firstName
      lastName
      email
      phone
      birthday
      updatedBy
      updatedOnUtc
      deletedOnUtc
      deletedBy
      isDeleted
      id
      privileges {
        access
        privilege {
          id
          type
        }
      }
    }
  }
`;

export const GET_CATEGORIES_QUERY = gql`
query{
  categories(request: {
    userId:null
  }){
    id
    categoryName
  }
}
`;

export const PRIVILEGES_QUERY = gql`
  query {
    allPrivileges(request: { userId: null }) {
      id
      type
    }
  }
`;

export const DASHBOARD_DATA = gql`
  query {
    dashboard(request: {}) {
      key
      value
    }
    annualRevenues(request: {}) {
      key
      value
    }
  }
`;

export const ENTITY_BY_ID_QUERY = gql`
  query ($in: GetSubjectByIdInput!) {
    companyById(request: $in) {
      companyName
      nipt
      phone
      companyStatus
      paymentStatus
      bills {
        numberOfBIll
        value
        billSTatus
      }
    }
  }
`;

export const ENTITY_TAXES_BY_ID = gql`
query ($CompanyId: UUID!) {
  generateTaxes(request: {
    companyId:$CompanyId
    userId: null
  }){
    taxName
    valueString
  }
}
`;

export const GET_BILLS_QUERY = gql`
query($companyId: UUID!){
  companyBills(request: {
    companyId:$companyId
    userId:null
  }){
    id
    debt
    period
    value
    companyName
    billSTatus
    numberOfBIll
  }
}
`;

export const GET_BILL_BY_ID_QUERY = gql`
query ($billId: UUID!) {
  billById(request: { billId: $billId }) {
    id
    valueString
    debt
    companyName
    nipt
    numberOfBIll
    taxesList{
      taxName
      valueString
    }
  }
}
`;
